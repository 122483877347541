import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slices";

const rootReducer = combineReducers({
  user: userReducer,
  // Add other reducers if any
});
// const rootReducer = (state, action) => {
//   if (action.type === "user/logout") {
//     state = undefined; // Resets the entire state
//   }
//   return appReducer(state, action);
// };
const store = configureStore({
  reducer: rootReducer,
});
export default store;
