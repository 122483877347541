import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function useInitialFinanceData() {
  const [fetchedData, setFetchedData] = useState([]);
  const [error, setError] = useState(null);
  const { config, role, id, IDPacient } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const fetchFinanceData = async ({
    dateTo,
    dateFrom,
    isFullDetails,
    filterEGN,
    filterName,
  }) => {
    setError(null);
    try {
      const requestBody = {
        [role === "patient"
          ? "IDPacient"
          : role === "doctor"
          ? "IDDoctorGlab"
          : "IDAffiliate"]:
          role === "patient" || role === "affiliate" ? IDPacient : id,
        DateFrom:
          dateFrom instanceof Date ? dateFrom.toISOString().split("T")[0] : "",
        DateTo:
          dateTo instanceof Date ? dateTo.toISOString().split("T")[0] : "",
        Identifier: filterEGN || "",
        Family: filterName || "",
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/GFinanceService/${
          isFullDetails ? "GetReferralReportOfSale" : "GetReferralSchemeOfSale"
        }${role.charAt(0).toUpperCase() + role.slice(1)}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFetchedData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching finance data:", error);
      setError(error.message || "An unknown error occurred.");
    }
  };

  return { fetchFinanceData, id, fetchedData, error };
}
