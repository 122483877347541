import MenuItem from "@mui/material/MenuItem";
import { cloneElement } from "react";
import { useSelector } from "react-redux";

export default function MenuDropItem({ text, icon, onClick, sx }) {
  const { config } = useSelector((state) => state.user);
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        color: config.dropdownMenuRootColor,
        backgroundColor: "#fff",
        borderRadius: "5px",
        width: 290,
        marginTop: "5px",
        marginBottom: "20px",
        "&:hover": {
          backgroundColor: config.dropdownMenuRootColor,
          color: "#fff",
          border: "1px solid #fff",
          cursor: "pointer",
        },
        ...sx,
      }}
    >
      {icon &&
        cloneElement(icon, {
          sx: {
            color: config.dropdownMenuRootColor,
            marginRight: "10px",
          },
        })}
      {text}
    </MenuItem>
  );
}
