import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Typography,
  IconButton,
  TextField, // SEARCH FIELD
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function GroupedCellVerticalTable({
  results,
  resultCell,
  currentPage,
  resultsPerPage,
  handlePageChange,
  handleViewResult,
  handleResultsPerPageChange,
  text,
  onClickData,
  buttonText,
  searchTerm, // Добавяне на променлива за търсене
  onSearchChange, // Добавяне на функция за промяна на търсенето
  filterEGN,
  filterName,
  noResultsFound,
}) {
  const { role, config } = useSelector((state) => state.user);
  const location = useLocation();

  const filteredResults =
    results?.filter((result) =>
      Object.values(result).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];
  const currentResults = filteredResults.slice(
    currentPage * resultsPerPage,
    (currentPage + 1) * resultsPerPage
  );
  useEffect(() => {
    // If search term is changed, reset to the first page
    handlePageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  const lastColumnIndex = resultCell.length - 1;
  const sumLastColumn = filteredResults?.reduce(
    (sum, row) => sum + (parseFloat(row[resultCell[lastColumnIndex]]) || 0),
    0
  );

  const renderGroupedCells = (text, result, resultCell) => {
    return (
      <>
        {/* First two cells combined */}
        <Typography variant="body2">
          {text[0]}: {result[resultCell[0]]}, {text[1]}: {result[resultCell[1]]}
        </Typography>

        {/* Next two cells combined */}
        <Typography variant="body2">
          {text[2]}: {result[resultCell[2]]}, {text[3]}: {result[resultCell[3]]}
        </Typography>

        {/* Name cells combined */}
        <Typography variant="body2">
          {text[4]}: {result[resultCell[4]]} {result[resultCell[5]]}{" "}
          {result[resultCell[6]]}
        </Typography>

        {/* Last two cells combined */}
        <Typography variant="body2">
          {text[7]}: {result[resultCell[7]]}, {text[8]}: {result[resultCell[8]]}
        </Typography>
      </>
    );
  };

  return (
    <Box
      className="table-box"
      sx={{
        display: "flex",
        justifyContent: "center",
        marginLeft: "10%",
        width: "80%",
        height: "calc(100vh - 50px)",
        overflowX: "auto",
        position: "relative",
        marginBottom: "30vh",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          width: "80%",
          maxHeight: "calc(100vh - 50px - 35px)",
          flexGrow: 1,
          overflow: "auto",
          // "&::-webkit-scrollbar": { display: "none" },
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Table
          stickyHeader
          aria-label="results table"
          sx={{
            // width: "100% !important",
            // display: "table",
            // tableLayout: "auto",
            "& tbody tr:nth-of-type(odd)": {
              backgroundColor: "#f6f6f8",
            },
            "& tbody tr:hover": {
              outline: `2px solid ${config.appBarColor}`,
              cursor: "pointer",
              outlineOffset: "-2px",
              boxShadow: "0 0 10px rgba(0, 81, 177, 0.3)",
              borderRadius: "8px",
            },
            "& td .MuiTypography-root": {
              fontSize: "11px",
            },
          }}
        >
          <TableHead
            sx={{
              "& .MuiTableCell-head": {
                backgroundColor: "#F6F6F8",
                maxWidth: "70%",
              },
            }}
          >
            {/* {role !== "patient" && (
              <TableRow
                sx={{
                  maxWidth: "70%",
                  position: "sticky",
                  top: 0,
                  display: "table-row",
                  zIndex: 15000, // to be visible when scrolling verrtically
                  backgroundColor: "white",
                }}
              >
                <TableCell
                  colSpan={text.length}
                  sx={{
                    padding: "5px",
                    display: "table-cell",
                    marginRight: "10%",
                  }}
                >
                  <TextField
                    label="Търсене по зададени критерии"
                    variant="outlined"
                    value={searchTerm}
                    onChange={onSearchChange}
                    fullWidth
                    size="small"
                  />
                </TableCell>
              </TableRow>
            )} */}
          </TableHead>
          <TableBody>
            {noResultsFound ? (
              <TableRow>
                <TableCell
                  colSpan={text.length} // Span across all columns
                  align="center"
                  sx={{
                    padding: "16px",
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)", // Optional: lighter text color
                  }}
                >
                  Няма намерени резултати.
                </TableCell>
              </TableRow>
            ) : (
              <>
                {currentResults.map((result, rowIndex) => (
                  <TableRow key={`row-${rowIndex}`} sx={{ width: "100%" }}>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "2px",
                        width: "100% !important",
                        whiteSpace: "nowrap",
                        display: "block",
                      }}
                    >
                      {renderGroupedCells(text, result, resultCell)}
                    </TableCell>
                  </TableRow>
                ))}
                {location.pathname.includes("detailed") && (
                  <TableRow sx={{ display: "inline" }}>
                    <TableCell
                      sx={{
                        width: "80%",
                        fontWeight: "bold",
                        textAlign: "left",
                        padding: "3px 5px",
                      }}
                    >
                      {`Общо: ${sumLastColumn.toFixed(2)}`}
                    </TableCell>
                    {Array(resultCell.length - 1)
                      .fill("")
                      .map((_, idx) => (
                        <TableCell
                          key={idx}
                          sx={{
                            fontWeight: "bold",
                            padding: "3px 5px",
                            fontSize: "0.775rem",
                          }}
                        />
                      ))}
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={filteredResults?.length}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={resultsPerPage}
          onRowsPerPageChange={handleResultsPerPageChange}
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          labelRowsPerPage="Покажи"
          labelDisplayedRows={() => null}
          sx={{
            background: "#F6F6F8",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "30px", // Adjusts overall row height
            minHeight: "unset", // Removes default minimum height
            "& .MuiTablePagination-root": {
              "& .MuiPaper-root": {
                left: "auto !important",
              },
            },
            "& .MuiTablePagination-toolbar": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 5px", // Reduces padding in pagination toolbar
              minHeight: "unset", // Removes any minimum height restriction
            },
            "& .MuiTablePagination-caption, & .MuiTablePagination-selectLabel":
              {
                margin: "0 5px",
                fontSize: "0.75rem", // Reduces font size of labels
                padding: "0 2px", // Adjusts padding
              },
            "& .MuiTablePagination-actions": {
              display: "flex",
              justifyContent: "flex-end",
              order: 2,
            },
            "& .MuiToolbar-root": {
              justifyContent: "center",
            },
            "& .MuiTablePagination-spacer": {
              flex: "0 0 auto",
            },
            "& .MuiSelect-root": {
              marginRight: "5px",
            },
          }}
          ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={(event) => onPageChange(event, page - 1)}
                disabled={page === 0}
                aria-label="Предишна страница"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography variant="body2" sx={{ margin: "0 10px" }}>
                {page + 1} / {Math.ceil(count / rowsPerPage)}
              </Typography>
              <IconButton
                onClick={(event) => onPageChange(event, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Следваща страница"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          )}
        />
      </TableContainer>
    </Box>
  );
}
