import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { changeConfig, changeLoading, changeRole, logout } from "../slices";
import axios from "axios";

export default function usePreloadConfig() {
  const { config } = useSelector((state) => state.user);
  const role = localStorage.getItem("role");
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadConfig = async () => {
      dispatch(changeLoading(true)); // Start loading
      try {
        const response = await axios.get("config.json");
        dispatch(changeConfig(response.data));
      } catch (error) {
        console.error("Error loading config:", error);
      } finally {
        dispatch(changeLoading(false)); // Stop loading
      }
    };
    loadConfig();

    switch (role) {
      case "patient":
        dispatch(changeRole("patient"));
        break;
      case "doctor":
        dispatch(changeRole("doctor"));
        break;
      case "affiliate":
        dispatch(changeRole("affiliate"));
        break;
      default:
        dispatch(changeRole("user"));
        break;
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(changeRole("user"));
      localStorage.clear();
      dispatch(logout());
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (config?.pageTitle) {
      document.title = config.pageTitle;
    }
  }, [config]);
}
