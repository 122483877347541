import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: "user",
  config: "configInitial",
  id: null,
  IDPacient: null,
  loading: false, //changed from flase for loading screen
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeRole: (state, action) => {
      state.role = action.payload;
    },
    changeConfig: (state, action) => {
      state.config = action.payload;
    },
    changeId: (state, action) => {
      state.id = action.payload;
    },
    changeIDPacient: (state, action) => {
      state.IDPacient = action.payload;
    },
    changeLoading: (state, action) => {
      state.loading = action.payload;
    },
    logout: (state) => {
      state = undefined;
    },
  },
});
export const {
  changeRole,
  changeConfig,
  changeId,
  changeIDPacient,
  changeLoading,
  logout,
} = userReducer.actions;
export default userReducer.reducer;
