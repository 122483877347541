import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeRole } from "../slices";

export const useValidateForm = (config, activeForm) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toastVisible, setToastVisible] = useState(false);
  const [lockMessage, setLockMessage] = useState(false);
  const showToast = () => {
    setToastVisible(true);
    setTimeout(() => setToastVisible(false), 3000);
  };
  const validateForm = async (type) => {
    const id = document.getElementById(`${type}-id`).value;
    const password = document.getElementById(`${type}-password`).value;
    const idError = document.getElementById(`${type}-id-error`);
    const passwordError = document.getElementById(`${type}-password-error`);

    idError.style.display = id ? "none" : "block";
    passwordError.style.display = password ? "none" : "block";

    if (id && password) {
      try {
        const requestBody = { username: id, password: password };

        const response = await axios.post(
          `${config.apiBaseUrl}/LoginService/Login${
            type.charAt(0).toUpperCase() + type.slice(1)
          }`,
          requestBody
        );

        if (response.status === 200 && response.data.value) {
          localStorage.setItem("token", response.data.value);

          if (activeForm === "patient-form") {
            dispatch(changeRole("patient"));
            localStorage.setItem("role", "patient");
            navigate("/patient");
          } else if (activeForm === "doctor-form") {
            dispatch(changeRole("doctor"));
            localStorage.setItem("role", "doctor");
            navigate("/doctor");
          } else if (activeForm === "affiliate-form") {
            dispatch(changeRole("affiliate"));
            localStorage.setItem("role", "affiliate");
            navigate("/affiliate");
          } else {
            showToast();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setLockMessage(error.response.data.error.message);
          setTimeout(() => setLockMessage(""), 10000);
        } else {
          showToast();
        }
      }
    }
  };

  return { validateForm, toastVisible, lockMessage };
};
