import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { Bulgarian } from "flatpickr/dist/l10n/bg.js";
import { useEffect, useState, Fragment } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function Datepickr({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  handleShowAllResults,
  handleShowFullDetails,
  filterEGN,
  setFilterEGN,
  filterName,
  setFilterName,
}) {
  const { role, config } = useSelector((state) => state.user);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let today;
    let oneYearback;

    if (role !== "patient") {
      // Set to the first and last day of the current month
      const firstDayOfMonth = new Date();
      firstDayOfMonth.setDate(1);
      firstDayOfMonth.setHours(12, 0, 0, 0);

      const lastDayOfMonth = new Date(firstDayOfMonth);
      lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
      lastDayOfMonth.setDate(0);
      lastDayOfMonth.setHours(23, 59, 59, 999);

      today = lastDayOfMonth;
      oneYearback = firstDayOfMonth;
    } else {
      today = new Date();
      oneYearback = new Date();
      oneYearback.setFullYear(today.getFullYear() - 1);
    }
    if (!dateFrom) {
      setDateFrom(oneYearback);
    }
    if (!dateTo) {
      setDateTo(today);
    }
  }, [location.pathname, dateFrom, dateTo, setDateFrom, setDateTo, role]);

  const handleDateFromChange = (date) => {
    const formattedDate = new Date(date[0].setHours(12, 0, 0, 0));
    setDateFrom(formattedDate);
  };

  const handleDateToChange = (date) => {
    const formattedDate = new Date(date[0]);
    formattedDate.setHours(23, 59, 59, 999); // End of day
    setDateTo(formattedDate);
  };

  const isDateRangeExceedsOneMonth = () => {
    if (!dateFrom || !dateTo) return false;
    const oneMonth = 31 * 24 * 60 * 60 * 1000; // Approximate one month in milliseconds
    return Math.abs(dateTo - dateFrom) > oneMonth;
  };

  const handleShowResults = () => {
    if (role === "patient") {
      handleShowAllResults();
    } else if (isDateRangeExceedsOneMonth() && !filterEGN && !filterName) {
      setErrorMessage(
        "Срока на търсене е повече от месец. Моля попълнете ЕГН и/или Фамилия!"
      );
    } else {
      setErrorMessage(null); // Clear error if all conditions are met
      setFilterEGN(filterEGN);
      setFilterName(filterName);
      handleShowAllResults(filterEGN, filterName);
    }
  };
  const handleShowDetails = () => {
    if (role === "patient") {
      handleShowFullDetails();
    } else if (isDateRangeExceedsOneMonth() && !filterEGN && !filterName) {
      setErrorMessage(
        "Срока на търсене е повече от месец. Моля попълнете ЕГН и/или Фамилия!"
      );
    } else {
      setErrorMessage(null); // Clear error if all conditions are met
      setFilterEGN(filterEGN);
      setFilterName(filterName);
      handleShowFullDetails(filterEGN, filterName);
    }
  };

  const buttonText = location.pathname.includes("detailed")
    ? "Подробна справка"
    : "Търси";
  return (
    <Box
      sx={{
        display: "flex",
        flexwrap: "wrap",
        // gap: "3px",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "10px auto",
        marginLeft: "10%",
      }}
    >
      {isMobile ? (
        <Grid item xs={12} sm={3} md={2} width={"90%"}>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems={"center"}
            sx={{
              width: "80%",
              display: "flex",
              justifyContent: "left",
              marginBottom: "2px",
            }}
          >
            <Grid item xs={6} flexGrow={1}>
              <Flatpickr
                value={dateFrom} // Начална дата
                onChange={handleDateFromChange}
                options={{
                  dateFormat: "d.m.Y",
                  defaultValue: dateFrom,
                  locale: Bulgarian,
                  disableMobile: true,
                  enableTime: false,
                }}
                render={({ defaultValue, value, ...props }, ref) => (
                  <TextField
                    {...props}
                    inputRef={ref}
                    value={defaultValue}
                    label="Начална дата"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      boxShadow: 1,
                      "&.MuiFormControl-root": {
                        height: "30px",
                      },
                      "& .MuiInputBase-root": {
                        height: "30px",
                        fontSize: "9px",
                      },
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontSize: "9px",
                        padding: "0px",
                      },
                    }}
                  />
                )}
                aria-label="Начална дата"
              />
            </Grid>
            <Grid item xs={6} flexGrow={1}>
              <Flatpickr
                value={dateTo} // Крайна дата
                onChange={handleDateToChange}
                options={{
                  dateFormat: "d.m.Y",
                  time_24hr: false,
                  locale: Bulgarian,
                  disableMobile: true,
                  enableTime: false,
                }}
                render={({ defaultValue, value, ...props }, ref) => (
                  <TextField
                    {...props}
                    inputRef={ref}
                    value={defaultValue}
                    label="Крайна дата"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      boxShadow: 1,
                      left: "6vw",
                      // marginRight: "30%",
                      "&.MuiFormControl-root": {
                        height: "30px",
                      },
                      "& .MuiInputBase-root": {
                        height: "30px",
                        fontSize: "9px",
                      },
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontSize: "9px",
                        padding: "0px",
                      },
                    }}
                  />
                )}
                aria-label="Крайна дата"
              />
            </Grid>
            {role !== "patient" && (
              <Fragment>
                {/* New EGN Filter Field */}
                <Grid item xs={3} flexGrow={1}>
                  <TextField
                    label="ЕГН"
                    variant="outlined"
                    fullWidth
                    value={filterEGN}
                    onChange={(e) => setFilterEGN(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      fontSize: "9px",
                      borderRadius: 2,
                      backgroundColor: "white",
                      boxShadow: 1,
                      "&.MuiFormControl-root": {
                        height: "30px",
                        textAlign: "center",
                        justifyContent: "center",
                      },
                      "& .MuiInputBase-root": {
                        height: "30px",
                        fontSize: "9px",
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "12px",
                        textAlign: "center",
                      },
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontSize: "9px",
                        padding: "3px",
                      },
                    }}
                  />
                </Grid>

                {/* New Name Filter Field */}
                <Grid item xs={3} flexGrow={1}>
                  <TextField
                    label="Фамилия"
                    variant="outlined"
                    fullWidth
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      fontSize: "9px",
                      borderRadius: 2,
                      backgroundColor: "white",
                      boxShadow: 1,
                      "&.MuiFormControl-root": {
                        height: "30px",
                        textAlign: "center",
                      },
                      "& .MuiInputBase-root": {
                        height: "30px",
                        fontSize: "9px",
                      },
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        fontSize: "9px",
                        padding: "3px",
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "12px",
                      },
                    }}
                  />
                </Grid>
              </Fragment>
            )}
            {/* New EGN Filter Field
            <Grid item xs={3} flexGrow={1}>
              <TextField
                label="ЕГН"
                variant="outlined"
                fullWidth
                value={filterEGN}
                onChange={(e) => setFilterEGN(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  fontSize: "9px",
                  borderRadius: 2,
                  backgroundColor: "white",
                  boxShadow: 1,
                  "&.MuiFormControl-root": {
                    height: "30px",
                    textAlign: "center",
                    justifyContent: "center",
                  },
                  "& .MuiInputBase-root": {
                    height: "30px",
                    fontSize: "9px",
                  },
                  "& .MuiFormLabel-root": {
                    fontSize: "12px",
                    textAlign: "center",
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                    fontSize: "9px",
                    padding: "3px",
                  },
                }}
              />
            </Grid>

            {/* New Name Filter Field 
            <Grid item xs={3} flexGrow={1}>
              <TextField
                label="Фамилия"
                variant="outlined"
                fullWidth
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  fontSize: "9px",
                  borderRadius: 2,
                  backgroundColor: "white",
                  boxShadow: 1,
                  "&.MuiFormControl-root": {
                    height: "30px",
                    textAlign: "center",
                  },
                  "& .MuiInputBase-root": {
                    height: "30px",
                    fontSize: "9px",
                  },
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                    fontSize: "9px",
                    padding: "3px",
                  },
                  "& .MuiFormLabel-root": {
                    fontSize: "12px",
                    // top: "-1.5vh",
                    // left: "10%",
                  },
                }}
              />
            </Grid> */}
            <Grid item xs={2} flexGrow={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowResults}
                fullWidth
                sx={{
                  height: "56px",
                  borderRadius: 2,
                  marginLeft: 0,
                  // marginRight: "1px",
                  "&.MuiButton-root": {
                    bgcolor: config.footerBarColor,
                    height: "30px",
                    fontSize: "9px",
                  },
                  "&.MuiButtonBase-root": {
                    padding: "0px",
                    margin: "0px",
                  },
                  "& div.MuiGrid-root": {
                    marginRight: "1px",
                  },
                  "&:hover": {
                    bgcolor: `${config.buttonSearchColorHover} !important`,
                  },
                }}
                aria-label="Покажи резултати"
              >
                {buttonText}
              </Button>
            </Grid>
            {location.pathname.includes("detailed") && (
              <Grid item xs={2} flexGrow={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShowDetails}
                  fullWidth
                  sx={{
                    height: "56px",
                    borderRadius: 2,
                    left: "9vw",
                    marginLeft: 0,
                    // marginRight: "5px",
                    "&:hover": {
                      bgcolor: `${config.buttonSearchColorHover} !important`,
                    },

                    "&.MuiButton-root": {
                      bgcolor: config.footerBarColor,
                      height: "30px",
                      fontSize: "9px",
                    },
                    "&.MuiButtonBase-root": {
                      padding: "0px",
                      margin: "0px",
                    },
                  }}
                  aria-label="Покажи резултати"
                >
                  Обобщена справка
                </Button>
              </Grid>
            )}
          </Grid>
          {errorMessage && (
            <Grid item xs={12} sx={{ marginTop: 2, width: "100%" }}>
              <Alert severity="warning" sx={{ fontSize: "9px" }}>
                {errorMessage}
              </Alert>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item xs={12} sm={3} md={2} width={"100%"}>
          <Grid
            container
            direction="row"
            spacing={0.5}
            justifyContent="space-between"
            alignItems={"center"}
            sx={{
              width: "80%",
              display: "flex",
              justifyContent: "left",
              marginBottom: "10px",
            }}
          >
            <Grid item xs={12} sm={3} md={2}>
              <Flatpickr
                value={dateFrom} // Начална дата
                onChange={handleDateFromChange}
                options={{
                  dateFormat: "d.m.Y",
                  enableTime: false,
                  locale: Bulgarian,
                  disableMobile: true,
                }}
                render={({ defaultValue, value, ...props }, ref) => (
                  <TextField
                    {...props}
                    inputRef={ref}
                    value={defaultValue}
                    label="Начална дата"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      boxShadow: 1,

                      "& .MuiInputBase-input": {
                        textAlign: "center",
                      },
                    }}
                  />
                )}
                aria-label="Начална дата"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Flatpickr
                value={dateTo} // Крайна дата
                onChange={handleDateToChange}
                options={{
                  dateFormat: "d.m.Y",
                  locale: Bulgarian,
                  disableMobile: true,
                  enableTime: false,
                }}
                render={({ defaultValue, value, ...props }, ref) => (
                  <TextField
                    {...props}
                    inputRef={ref}
                    value={defaultValue}
                    label="Крайна дата"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      boxShadow: 1,
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                      },
                    }}
                  />
                )}
                aria-label="Крайна дата"
              />
            </Grid>
            {/* New EGN Filter Field */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              flexGrow={1}
              sx={{ display: role === "patient" ? "none" : "block" }}
            >
              <TextField
                label="ЕГН"
                variant="outlined"
                fullWidth
                value={filterEGN}
                onChange={(e) => setFilterEGN(e.target.value)}
                sx={{
                  width: "100%",
                  fontSize: "9px",
                  borderRadius: 2,
                  backgroundColor: "white",
                  boxShadow: 1,
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
              />
            </Grid>

            {/* New Name Filter Field */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              flexGrow={1}
              sx={{ display: role === "patient" ? "none" : "block" }}
            >
              <TextField
                label="Фамилия"
                variant="outlined"
                fullWidth
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                sx={{
                  width: "100%",
                  fontSize: "9px",
                  borderRadius: 2,
                  backgroundColor: "white",
                  boxShadow: 1,
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} flexGrow={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowResults}
                fullWidth
                sx={{
                  height: "56px",
                  borderRadius: 2,
                  "&:hover": {
                    bgcolor: `${config.buttonSearchColorHover} !important`,
                  },
                  "&.MuiButton-root": {
                    bgcolor: config.footerBarColor,
                  },
                }}
                aria-label="Покажи резултати"
              >
                {buttonText}
              </Button>
            </Grid>
            {location.pathname.includes("detailed") && (
              <Grid item xs={12} sm={3} md={2} flexGrow={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShowDetails}
                  fullWidth
                  sx={{
                    height: "56px",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: `${config.buttonSearchColorHover} !important`,
                    },
                    "&.MuiButton-root": {
                      bgcolor: config.footerBarColor,
                    },
                  }}
                  aria-label="Покажи резултати"
                >
                  Обобщена справка
                </Button>
              </Grid>
            )}
          </Grid>
          {errorMessage && (
            <Grid item xs={12} sx={{ marginTop: 2, width: "100%" }}>
              <Alert severity="warning">{errorMessage}</Alert>
            </Grid>
          )}
        </Grid>
      )}
      {/* {errorMessage && (
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Alert
            severity="warning"
            sx={{
              width: isMobile ? "90%" : "60%", // Adjust width for mobile
              fontSize: isMobile ? "12px" : "16px", // Adjust font size for readability
              textAlign: "center", // Center align text
              padding: isMobile ? "8px" : "16px", // Add some spacing
            }}
          >
            {errorMessage}
          </Alert>
        </Grid>
      )} */}
    </Box>
  );
}
