import { useState } from "react";
import LoginFormPacient from "./LoginFormPacient";
import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";

function LoginFormFull({
  buttonId,
  buttonOnClick,
  buttonText,
  buttonIcon,
  id,
  visible,
  onSubmit,
  errors = [],
  inputs = [],
  placeholder,
}) {
  const [isActive, setIsActive] = useState(false);
  const { config } = useSelector((state) => state.user);

  return (
    <div className="loginformfull">
      <Button
        variant="text"
        id={buttonId}
        className={`main-btn ${isActive ? "active" : ""}`}
        onClick={() => {
          setIsActive(!isActive);
          buttonOnClick && buttonOnClick();
        }}
        sx={{
          textTransform: "none",
          borderRadius: 8,
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          backgroundColor: isActive
            ? config?.buttonColors?.active || "#C5D5EA"
            : config?.buttonColors?.default || "#F6F6F8",
          border: isActive
            ? `1px solid ${config?.loginTextColor}`
            : "1px solid transparent",
          borderBottomLeftRadius: isActive ? 0 : "5px",
          borderBottomRightRadius: isActive ? 0 : "5px",
          "&:hover": {
            backgroundColor: config?.buttonColors?.hover || "#C5D5EA",
            border: `1px solid ${config.loginTextColor} !important`,
          },
          color: config.buttonInnerTextColor,
          ...(isActive && {
            "& p": {
              backgroundColor: config?.buttonColors?.hover || "#C5D5EA",
            },
            "& .dropDownArrow": {
              backgroundColor: config?.buttonColors?.hover || "#C5D5EA",
            },
          }),
        }}
      >
        <p>{buttonText}</p>

        <KeyboardArrowDownIcon
          className="dropDownArrow"
          sx={{
            marginLeft: "10px",
            transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Button>
      <LoginFormPacient
        id={id}
        visible={visible}
        onSubmit={onSubmit}
        errors={errors}
        inputs={inputs}
        placeholder={placeholder}
      />
    </div>
  );
}
export default LoginFormFull;
