import { Fragment, useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { AppHeader, ContentOptions, Footer } from "./Components";
import LoginChoice from "./pages/LoginChoice";
import { useSelector } from "react-redux";
import Examinations from "./pages/Examinations";
import Results from "./pages/Results";
import Documents from "./pages/Documents";
import DoctorExaminations from "./pages/DoctorExaminations";
import DoctorResults from "./pages/DoctorResults";
import AffiliateExaminations from "./pages/AffiliateExaminations";
import AffiliateResults from "./pages/AffiliateResults";
import Attachments from "./pages/Attachments";
import Spinner from "./Components/Spinner";
import { useGetIdData, usePreloadConfig } from "./hooks";
import DetailedFinance from "./pages/DetailedFinance";

// Loading Screen Component
// const LoadingScreen = ({ logoPath }) => (
//   <div
//     style={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       height: "100vh",
//       backgroundColor: "#f0f0f0",
//       color: "#333",
//       fontSize: "2.5rem",
//     }}
//   >
//     {/* {logoPath && (
//       <img
//         src="/logo-Alexandra.png"
//         alt="Logo"
//         style={{ width: "150px", marginBottom: "20px" }}
//       />
//     )} */}
//     <p>Зареждане...</p>
//   </div>
// );
const App = () => {
  const location = useLocation();
  const { config, loading } = useSelector((state) => state.user);
  useEffect(() => {
    const preventZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventZoom, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventZoom);
    };
  }, []);
  usePreloadConfig();
  useGetIdData();
  useEffect(() => {
    const setBodyHeight = () => {
      requestAnimationFrame(() => {
        document.body.style.height = `${window.innerHeight}px`;
        document.body.getBoundingClientRect();
      });
    };
    setTimeout(setBodyHeight, 100);
    window.addEventListener("resize", setBodyHeight);
    return () => {
      window.removeEventListener("resize", setBodyHeight);
    };
  }, [location.pathname]);

  useEffect(() => {
    // Dynamically create and append favicon links from config
    if (config && config.favicons && Array.isArray(config.favicons)) {
      config.favicons.forEach((favicon) => {
        const link = document.createElement("link");
        link.rel = favicon.rel;
        link.sizes = favicon.sizes;
        link.href = favicon.href;
        document.head.appendChild(link);
      });
    }
  }, [config, config?.favicons]);

  return (
    <Fragment>
      <AppHeader />
      {loading && <Spinner />}

      <Routes>
        <Route path="/" element={<LoginChoice />} />
        <Route path="/examinations" element={<Examinations />} />
        <Route path="/results" element={<Results />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/doctorexaminations" element={<DoctorExaminations />} />
        <Route path="/doctorresults" element={<DoctorResults />} />
        <Route
          path="/affiliateexaminations"
          element={<AffiliateExaminations />}
        />
        <Route path="/affiliateresults" element={<AffiliateResults />} />
        <Route path="/attachments" element={<Attachments />} />
        {["/affiliate", "/doctor", "/patient"].map((path) => (
          <Route key={path} path={path} element={<ContentOptions />} />
        ))}
        <Route path="/detailedReference" element={<DetailedFinance />} />
      </Routes>
      <Footer />
    </Fragment>
  );
};

export default App;
