import React, { Fragment, useEffect, useState } from "react";
import { Flatpickr, TextContentInfo } from "../Components";
import Table from "../Components/Table/Table";
import {
  dataDetailedFinance,
  dataDetailedFinanceGrouped,
  dataFinance,
} from "../utils/helpers";
import { useInitialFinanceData } from "../hooks";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLoading } from "../slices";
import { useMediaQuery } from "@mui/material";
import GroupedCellVerticalTable from "../Components/Table/GroupedCellVerticalTable";

export default function DetailedFinance() {
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(100);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [financeData, setFinanceData] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [isSmallTable, setIsSmallTable] = useState(false);
  const [filterEGN, setFilterEGN] = useState("");
  const [filterName, setFilterName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const { fetchFinanceData, fetchedData } = useInitialFinanceData();

  useEffect(() => {
    if (location.state?.data && location.state.data.length > 0) {
      setFinanceData(location.state.data);
    }
  }, [location.state?.data]);

  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      setFinanceData(fetchedData);
    }
  }, [fetchedData]);

  const handleShowAllResults = async () => {
    if (!dateFrom || !dateTo) return;
    setIsSmallTable(false);
    dispatch(changeLoading(true));

    const newData = await fetchFinanceData({
      dateTo,
      dateFrom,
      isFullDetails: true,
      filterEGN,
      filterName,
    });
    setFinanceData(newData || []);
    dispatch(changeLoading(false));
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };
  const handleShowFullDetails = async () => {
    if (!dateFrom || !dateTo) return;
    dispatch(changeLoading(true));
    setIsSmallTable(true);
    const newdata = await fetchFinanceData({
      dateTo,
      dateFrom,
      isFullDetails: false,
      filterEGN,
      filterName,
    });
    setFinanceData(newdata || []);
    dispatch(changeLoading(false));
  };

  const noResultsFound = financeData.length === 0;
  return (
    <Fragment>
      <TextContentInfo
        sx={{
          "& .css-kg3fcp": { width: "80%" },
        }}
        text={
          isSmallTable
            ? "Обобщена финансова справка"
            : "Подробна финансова справка"
        }
      />
      <Flatpickr
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        handleShowAllResults={handleShowAllResults}
        handleShowFullDetails={handleShowFullDetails}
        filterEGN={filterEGN}
        setFilterEGN={setFilterEGN}
        filterName={filterName}
        setFilterName={setFilterName}
        setErrorMessage={setErrorMessage}
      />
      {isMobile ? (
        !isSmallTable ? (
          <GroupedCellVerticalTable
            results={financeData}
            resultCell={[
              "IdEpruvetka",
              "WebResultsPass",
              "DateRefferal",
              "IdentifierPac",
              "GivenPacName",
              "MiddlePacName",
              "FamilyPacName",
              "Scheme",
              "SumScheme",
            ]}
            currentPage={currentPage}
            resultsPerPage={resultsPerPage}
            handlePageChange={handlePageChange}
            handleResultsPerPageChange={handleResultsPerPageChange}
            text={dataDetailedFinanceGrouped}
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            isSmallTable={isSmallTable}
            filterEGN={filterEGN}
            filterName={filterName}
            noResultsFound={noResultsFound}
          />
        ) : (
          <Table
            results={financeData}
            resultCell={["Scheme", "SumScheme"]}
            currentPage={currentPage}
            resultsPerPage={resultsPerPage}
            // handleViewResult={""}
            // onClickData={["IDPacient"]}
            handlePageChange={handlePageChange}
            handleResultsPerPageChange={handleResultsPerPageChange}
            text={dataFinance}
            // buttonText="Виж преглед"
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            isSmallTable={isSmallTable}
            filterEGN={filterEGN}
            filterName={filterName}
            noResultsFound={noResultsFound}
          />
        )
      ) : !isSmallTable ? (
        <Table
          results={financeData}
          resultCell={[
            "IdEpruvetka",
            "WebResultsPass",
            "DateRefferal",
            "IdentifierPac",
            "GivenPacName",
            "MiddlePacName",
            "FamilyPacName",
            "Scheme",
            "SumScheme",
          ]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          // handleViewResult={""}
          // onClickData={["IDPacient"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataDetailedFinance}
          // buttonText="Виж преглед"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          isSmallTable={isSmallTable}
          filterEGN={filterEGN}
          filterName={filterName}
          noResultsFound={noResultsFound}
        />
      ) : (
        <Table
          results={financeData}
          resultCell={["Scheme", "SumScheme"]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          // handleViewResult={""}
          // onClickData={["IDPacient"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataFinance}
          // buttonText="Виж преглед"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          isSmallTable={isSmallTable}
          filterEGN={filterEGN}
          filterName={filterName}
          noResultsFound={noResultsFound}
        />
      )}
    </Fragment>
  );
}
