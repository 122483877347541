import React, { Fragment, useEffect, useState } from "react";
import TextContentInfo from "../Components/TextContentInfo";
import Table from "../Components/Table/Table";
import { dataAttachments } from "../utils/helpers";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLoading } from "../slices/userReducer";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { Box, useMediaQuery } from "@mui/material";
import AppHeader from "../Components/navigation/AppHeader";
import { PdfViewer } from "../Components";
import VerticalTable from "../Components/Table/VerticalTable";
import { jwtDecode } from "jwt-decode";

export default function Attachments() {
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(100);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { config, role } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const [setIdEpruvetka] = useState(null);
  const [attachmentData, setAttachmentsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [base64Data, setBase64Data] = useState(null);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (location.state?.IdEpruvetka) {
      setIdEpruvetka(location.state.IdEpruvetka);
    }
  }, [location.state, setIdEpruvetka]);

  useEffect(() => {
    if (location.state?.data && location.state.data.length > 0) {
      setAttachmentsData(location.state.data);
    }
  }, [location.state]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };
  const handleViewResult = async ([Id]) => {
    dispatch(changeLoading(true));
    try {
      const requestBody = {
        IDDocument: Id,
      };

      const responsePdf = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetOtherDocumentsPatientPDF`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const base64Pdf = responsePdf.data[0].DocumentPdf;
      setBase64Data(base64Pdf);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching document PDF:", error);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  let userName = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    userName = decodedToken.pac || "No patient name!";
  }
  return (
    <Fragment>
      <TextContentInfo text="Прикачени документи" />

      {isMobile ? (
        <VerticalTable
          results={attachmentData}
          resultCell={["DateChange", "Opis"]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["Id"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataAttachments}
          buttonText="Виж документ"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
      ) : (
        <Table
          results={attachmentData}
          resultCell={["DateChange", "Opis"]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["Id"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataAttachments}
          buttonText="Виж документ"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 25000,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 2,
            boxShadow: 24,
          }}
        >
          <AppHeader />
          {base64Data && (
            <PdfViewer
              base64Data={base64Data}
              fileName={
                role === "patient"
                  ? `${attachmentData[0].DateChange}_${attachmentData[0].Opis}_${userName}`
                  : role === "doctor"
                  ? `${attachmentData[0].DateChange}_${attachmentData[0].Opis}`
                  : role === "affiliate"
                  ? `${attachmentData[0].DateChange}_${attachmentData[0].Opis}`
                  : ""
              }
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </Fragment>
  );
}
