import { Fragment, useEffect, useState } from "react";
import Flatpickr from "../Components/Flatpickr";
import Table from "../Components/Table/Table";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { dataDocuments } from "../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import TextContentInfo from "../Components/TextContentInfo";
import { changeLoading } from "../slices/userReducer";
import Modal from "@mui/material/Modal";
import { Box, useMediaQuery } from "@mui/material";
import AppHeader from "../Components/navigation/AppHeader";
import { PdfViewer } from "../Components";
import VerticalTable from "../Components/Table/VerticalTable";
import { jwtDecode } from "jwt-decode";

export default function Examinations() {
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(100);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const { role, config, id, IDPacient } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const [pacientResults, setPacientResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [base64Data, setBase64Data] = useState(null);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [errorMessage, setErrorMessage] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (location.state?.data && location.state.data.length > 0) {
      setPacientResults(location.state.data);
    }
  }, [location.state?.data, navigate]);

  useEffect(() => {
    if (pacientResults && pacientResults.length > 0) {
      setPacientResults(pacientResults);
    }
  }, [pacientResults]);

  const handleShowAllResults = async () => {
    if (!dateFrom || !dateTo) return;
    dispatch(changeLoading(true));

    try {
      const requestBody = {
        IDPacientGMedics: id,
        IDPacientGLab: IDPacient,
        DateFrom: dateFrom.toISOString().split("T")[0],
        DateTo: dateTo.toISOString().split("T")[0],
      };
      const response = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetOtherDocuments${
          role.charAt(0).toUpperCase() + role.slice(1)
        }`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPacientResults(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching examination results:", error);
    } finally {
      dispatch(changeLoading(false));
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };

  const handleViewResult = async ([Id]) => {
    dispatch(changeLoading(true));
    try {
      const requestBody = {
        IDDocument: Id,
      };

      const responsePdf = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetOtherDocumentsPatientPDF`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (responsePdf.status === 200 && responsePdf.data.length > 0) {
        const base64Pdf = responsePdf.data[0].DocumentPdf;
        setBase64Data(base64Pdf);
        setOpenModal(true);
      } else {
        // Stop spinner
        throw new Error("No access to this document");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error.message);
      // Show message to the user
      setErrorMessage("В момента няма достъп до този документ.");
    } finally {
      dispatch(changeLoading(false));
    }
  };
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(""); // Clear error message after delay
      }, 5000);

      return () => clearTimeout(timer); // Cleanup timer on unmount or when errorMessage changes
    }
  }, [errorMessage]);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  let userName = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    userName = decodedToken.pac || "No patient name!";
  }
  const noResultsFound = pacientResults.length === 0;
  return (
    <Fragment>
      <TextContentInfo text="Документи" />

      <Flatpickr
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        handleShowAllResults={handleShowAllResults}
      />

      {isMobile ? (
        <VerticalTable
          results={pacientResults}
          resultCell={["DateChange", "Opis"]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["Id"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataDocuments}
          buttonText="Виж документ"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          noResultsFound={noResultsFound}
        />
      ) : (
        <Table
          results={pacientResults}
          resultCell={["DateChange", "Opis"]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["Id"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataDocuments}
          buttonText="Виж документ"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          noResultsFound={noResultsFound}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 25000,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 2,
            boxShadow: 24,
          }}
        >
          <AppHeader />
          {base64Data && (
            <PdfViewer
              base64Data={base64Data}
              fileName={`${pacientResults[0].DateChange}_${pacientResults[0].Opis}_${userName}`}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </Fragment>
  );
}
