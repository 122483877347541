import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function ButtonContent({ onClick, header, description, icon }) {
  const { config } = useSelector((state) => state.user);
  return (
    <Button
      sx={{
        textTransform: "none",
        color: config.contentButtonColor,
        bgcolor: config.bgContentButtonColor,
        "&:hover": { bgcolor: config.contentButtonInnerTextColor },
      }}
      onClick={onClick}
    >
      <div className="contentinfo">
        <strong>{header}</strong>
        <span>{description}</span>
      </div>
      {icon}
    </Button>
  );
}
