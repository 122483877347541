import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
  filterEGN,
  filterName,
  noResultsFound,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch, useSelector } from "react-redux";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { changeLoading } from "../../slices/userReducer";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import StartIcon from "@mui/icons-material/Start";
import ErrorIcon from "@mui/icons-material/Error";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
export default function VerticalTable({
  results,
  resultCell,
  currentPage,
  resultsPerPage,
  handlePageChange,
  handleViewResult,
  handleResultsPerPageChange,
  text,
  onClickData,
  buttonText,
  searchTerm, // Добавяне на променлива за търсене
  onSearchChange, // Добавяне на функция за промяна на търсенето
  filterEGN,
  filterName,
  noResultsFound,
}) {
  const { role, config } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [attachmentData, setAttachmentData] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const filteredResults =
    results?.filter((result) =>
      Object.values(result).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];
  const currentResults = filteredResults.slice(
    currentPage * resultsPerPage,
    (currentPage + 1) * resultsPerPage
  );
  useEffect(() => {
    // If search term is changed, reset to the first page
    handlePageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filterName, filterEGN]);
  const showAllAttachments = async (IdEpruvetka) => {
    dispatch(changeLoading(true));
    try {
      const requestBody = {
        IdEpruvetka: IdEpruvetka,
      };
      const response = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetOtherDocumentsRefferal`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAttachmentData(response.data);
      navigate("/attachments", { state: { data: response.data } });
    } catch (error) {
      console.error("Error fetching attachment results:", error);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  const lastColumnIndex = resultCell.length - 1;
  const sumLastColumn = filteredResults?.reduce(
    (sum, row) => sum + (parseFloat(row[resultCell[lastColumnIndex]]) || 0),
    0
  );
  return (
    <Box
      className="table-box"
      sx={{
        display: "flex",
        justifyContent: "center",
        marginLeft: "10%",
        width: "80%",
        height: "calc(100vh - 50px)",
        overflowX: "auto",
        position: "relative",
        marginBottom: "30vh",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          maxHeight: "calc(100vh - 50px - 35px)",
          // height: "100%",
          overflowX: "auto",
          // "&::-webkit-scrollbar": { display: "none" },
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Table
          aria-label="results table"
          sx={{
            width: "100%",
            "& tbody tr:nth-of-type(odd)": {
              backgroundColor: "#f6f6f8",
            },
            "& tbody tr:hover": {
              outline: `2px solid ${config.appBarColor}`,
              cursor: "pointer",
              outlineOffset: "-2px",
              boxShadow: "0 0 10px rgba(0, 81, 177, 0.3)",
              borderRadius: "8px",
            },
          }}
        >
          <TableHead
            sx={{
              "& .MuiTableCell-head": {
                backgroundColor: "#F6F6F8",
              },
            }}
          >
            {/* {role !== "patient" && (
              <TableRow
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 15000, // to be visible when scrolling verrtically
                  backgroundColor: "white",
                }}
              >
                <TableCell colSpan={text.length} sx={{ padding: "5px" }}>
                  <TextField
                    label="Търсене по зададени критерии"
                    variant="outlined"
                    value={searchTerm}
                    onChange={onSearchChange}
                    fullWidth
                    size="small"
                  />
                </TableCell>
              </TableRow>
            )} */}
          </TableHead>
          <TableBody>
            {noResultsFound ? (
              <TableRow>
                <TableCell
                  colSpan={text.length} // Span across all columns
                  align="center"
                  sx={{
                    padding: "16px",
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)", // Optional: lighter text color
                  }}
                >
                  Няма намерени резултати.
                </TableCell>
              </TableRow>
            ) : (
              <>
                {currentResults.map((result, rowIndex) => (
                  <TableRow key={`row-${rowIndex}`}>
                    <TableCell
                      align="left"
                      sx={{ padding: "2px", width: "100% !important" }}
                    >
                      {text.map((label, index) => {
                        const cellValue = result[resultCell[index]];
                        // Check if the label is "Документи" and skip rendering it here
                        if (
                          label.includes("Документи") ||
                          label.includes("Резултат") ||
                          label.includes("Преглед")
                        )
                          return null;
                        if (label === "Статус") {
                          return (
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                              key={index}
                            >
                              <Typography variant="body2">
                                {label}: {}
                              </Typography>
                              {location.pathname.includes("results") && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {result.Status === 1 ? (
                                    <CheckCircleOutlineOutlinedIcon
                                      sx={{ color: "green" }}
                                      titleAccess="Готово"
                                    />
                                  ) : result.Status === 3 ? (
                                    <UpdateIcon
                                      sx={{ color: "orange" }}
                                      titleAccess="Обработва се"
                                    />
                                  ) : result.Status === 2 ? (
                                    <StartIcon
                                      sx={{ color: "red" }}
                                      titleAccess="Няма резултат"
                                    />
                                  ) : (
                                    <ErrorIcon
                                      sx={{ color: "red" }}
                                      titleAccess="Грешка"
                                    />
                                  )}
                                  {(() => {
                                    const hasPermission =
                                      role === "patient"
                                        ? config.allowSeeResultPatient
                                        : role === "doctor"
                                        ? config.allowSeeResultDoctor
                                        : role === "affiliate"
                                        ? config.allowSeeResultAffiliate
                                        : false;

                                    if (hasPermission) {
                                      return result.Pay === 1 ? (
                                        <AttachMoneyIcon
                                          sx={{ color: "green" }}
                                          titleAccess="Платено"
                                        />
                                      ) : (
                                        <AttachMoneyIcon
                                          sx={{ color: "red" }}
                                          titleAccess="Неплатено"
                                        />
                                      );
                                    }
                                    return null;
                                  })()}
                                </Box>
                              )}
                            </Box>
                          );
                        }
                        return (
                          <Typography key={index} variant="body2">
                            {label}: {result[resultCell[index - 1]]}
                          </Typography>
                        );
                      })}
                      {!(
                        location.pathname.includes("Finance") ||
                        location.pathname.includes("detailed")
                      ) && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            sx={{ marginRight: "8px" }}
                          >
                            {text.find(
                              (label) =>
                                label.includes("Документи") ||
                                label.includes("Резултат") ||
                                label.includes("Преглед")
                            )}
                            : {result.Documents || ""}
                          </Typography>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleViewResult(
                                onClickData.map((e) => result[e])
                              )
                            }
                            sx={{
                              textTransform: "none",
                              fontSize: {
                                xs: "0.75rem",
                                sm: "0.875rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px 8px",
                                sm: "6px 12px",
                                md: "8px 16px",
                              },
                              backgroundColor: config.footerBarColor,
                              "&:hover": {
                                backgroundColor: `${config.buttonSearchColorHover} !important`,
                              },
                              display: "block",
                              marginLeft: !location.pathname.includes("results")
                                ? "auto"
                                : "unset",
                              marginRight: "10px",
                            }}
                            aria-label={"Преглед на резултат"}
                          >
                            {buttonText}
                          </Button>
                        </Box>
                      )}
                      {location.pathname.includes("results") && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center", // Align items on the same row
                            gap: "108px", // Add some spacing between elements
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ marginRight: "8px" }}
                          >
                            {text.find((label) => label.includes("Документи"))}:{" "}
                            {result.Documents || ""}
                          </Typography>

                          {result.Document === 1 && (
                            <AttachmentIcon
                              sx={{
                                color: config.paperClipColor,
                                // fontSize: "40px",
                                "&:hover": {
                                  transform: "scale(1.2)",
                                  transition: "transform 0.3s ease-in-out",
                                  color: config.paperClipColorHover,
                                },
                              }}
                              onClick={() => {
                                showAllAttachments(result.IdEpruvetka);
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {location.pathname.includes("detailed") && (
                  <TableRow>
                    {Array(resultCell.length - 1)
                      .fill("")
                      .map((_, idx) => (
                        <TableCell
                          key={idx}
                          sx={{
                            fontWeight: "bold",
                            padding: "3px 5px",
                            fontSize: "0.775rem",
                          }}
                        />
                      ))}
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "right",
                        padding: "3px 5px",
                        fontSize: "0.775rem",
                      }}
                    >
                      {`Общо: ${sumLastColumn.toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={filteredResults?.length}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={resultsPerPage}
          onRowsPerPageChange={handleResultsPerPageChange}
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          labelRowsPerPage="Покажи"
          labelDisplayedRows={() => null}
          sx={{
            background: "#F6F6F8",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "30px", // Adjusts overall row height
            minHeight: "unset", // Removes default minimum height
            "& .MuiTablePagination-root": {
              "& .MuiPaper-root": {
                left: "auto !important",
              },
            },
            "& .MuiTablePagination-toolbar": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 5px", // Reduces padding in pagination toolbar
              minHeight: "unset", // Removes any minimum height restriction
            },
            "& .MuiTablePagination-caption, & .MuiTablePagination-selectLabel":
              {
                margin: "0 5px",
                fontSize: "0.75rem", // Reduces font size of labels
                padding: "0 2px", // Adjusts padding
              },
            "& .MuiTablePagination-actions": {
              display: "flex",
              justifyContent: "flex-end",
              order: 2,
            },
            "& .MuiToolbar-root": {
              justifyContent: "center",
            },
            "& .MuiTablePagination-spacer": {
              flex: "0 0 auto",
            },
            "& .MuiSelect-root": {
              marginRight: "5px",
            },
          }}
          ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={(event) => onPageChange(event, page - 1)}
                disabled={page === 0}
                aria-label="Предишна страница"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography variant="body2" sx={{ margin: "0 10px" }}>
                {page + 1} / {Math.ceil(count / rowsPerPage)}
              </Typography>
              <IconButton
                onClick={(event) => onPageChange(event, page + 1)}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Следваща страница"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          )}
        />
      </TableContainer>
    </Box>
  );
}
