import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function useInitialResultsData() {
  const [fetchedData, setFetchedData] = useState([]);
  const [error, setError] = useState(null);
  const { config, role, id, IDPacient } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");

  const fetchResultsData = async ({
    dateTo,
    dateFrom,
    filterEGN,
    filterName,
  }) => {
    setError(null);
    try {
      const requestBody = {
        [role === "patient"
          ? "IDPacient"
          : role === "affiliate"
          ? "IDAffiliate"
          : "IDDoctorGlab"]: role === "doctor" ? id : IDPacient,
        DateFrom:
          dateFrom instanceof Date ? dateFrom.toISOString().split("T")[0] : "",
        DateTo:
          dateTo instanceof Date ? dateTo.toISOString().split("T")[0] : "",
        Identifier: filterEGN || "",
        Family: filterName || "",
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetReferral${
          role.charAt(0).toUpperCase() + role.slice(1)
        }`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFetchedData(response.data);

      return response.data;
    } catch (error) {
      console.error("Error fetching examination results:", error);
      setError(error.message || "An unknown error occurred.");
    }
  };

  return { fetchResultsData, IDPacient, fetchedData, error };
}
