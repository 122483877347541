import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableCellData from "./TableCellData";
import { useDispatch, useSelector } from "react-redux";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { changeLoading } from "../../slices/userReducer";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import StartIcon from "@mui/icons-material/Start";
import ErrorIcon from "@mui/icons-material/Error";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
export default function ResultsTable({
  results,
  resultCell,
  currentPage,
  resultsPerPage,
  handlePageChange,
  handleViewResult,
  handleResultsPerPageChange,
  text,
  onClickData,
  buttonText,
  searchTerm, // Добавяне на променлива за търсене
  onSearchChange,
  isSmallTable,
  filterEGN,
  filterName,
  noResultsFound,
}) {
  const { config, role } = useSelector((state) => state.user);
  // const isMobile = useMediaQuery("(max-width: 960px)");
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [attachmentData, setAttachmentData] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  // const isMobile = useMediaQuery("(max-width: 960px)");
  const align = (index) => {
    if (index === text?.length - 1) {
      return "right";
    }
    return "left";
  };

  // Филтриране на резултатите в зависимост от търсенето
  const filteredResults =
    results?.filter((result) =>
      Object.values(result).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) || [];

  const currentResults = filteredResults.slice(
    currentPage * resultsPerPage,
    (currentPage + 1) * resultsPerPage
  );
  useEffect(() => {
    // If search term is changed, reset to the first page
    handlePageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filterName, filterEGN]);

  const showAllAttachments = async (IdEpruvetka) => {
    dispatch(changeLoading(true));
    try {
      const requestBody = {
        IdEpruvetka: IdEpruvetka,
      };
      const response = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetOtherDocumentsRefferal`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAttachmentData(response.data);
      console.log(response.data);

      navigate("/attachments", { state: { data: response.data } });
    } catch (error) {
      console.error("Error fetching attachment results:", error);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  const lastColumnIndex = resultCell.length - 1;
  const sumLastColumn = filteredResults?.reduce(
    (sum, row) => sum + (parseFloat(row[resultCell[lastColumnIndex]]) || 0),
    0
  );
  let foundResultsText = "";
  if (location.pathname.includes("examinations")) {
    foundResultsText = "Няма намерени прегледи";
  } else if (location.pathname.includes("results")) {
    foundResultsText = "Няма намерени резултати";
  } else if (location.pathname.includes("detailed")) {
    foundResultsText = "Няма намерени резултати";
  } else if (location.pathname.includes("documents")) {
    foundResultsText = "Няма намерени документи";
  }
  return (
    <Box
      className="table-box"
      sx={{
        display: "flex",
        marginLeft: "10%",
        justifyContent: "center",
        width: "80%",
        height: "calc(100vh - 40px)",
        overflow: "auto",
        position: "relative",
        marginBottom: "10vh",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          width: "80%",
          maxHeight: "calc(100vh - 50px - 35px)",
          flexGrow: 1,
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Table
          stickyHeader
          aria-label="results table"
          sx={{
            "& tbody tr:nth-of-type(odd)": {
              backgroundColor: "#f6f6f8",
            },
            "& tbody tr:hover": {
              outline: `2px solid ${config.footerBarColor}`,
              cursor: "pointer",
              outlineOffset: "-2px",
              boxShadow: "0 0 10px rgba(0, 81, 177, 0.3)",
              borderRadius: "8px",
            },
          }}
        >
          <TableHead
            sx={{
              "& .MuiTableCell-head": {
                backgroundColor: "#F6F6F8",
              },
            }}
          >
            {/* search field */}
            {/* {role !== "patient" && !isMobile && (
              <TableRow
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 15000, // to be visible when scrolling vertically
                  backgroundColor: "white",
                }}
              >
                <TableCell colSpan={text.length} sx={{ padding: "10px" }}>
                  <TextField
                    label="Търсене по зададени критерии"
                    variant="outlined"
                    value={searchTerm}
                    onChange={onSearchChange}
                    fullWidth
                    size="small"
                    sx={{
                      "&.Mui-focused fieldset": {
                        borderColor: "red",
                      },
                      "&.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
                        borderColor: "green",
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            )} */}

            <TableRow sx={{ backgroundColor: "white" }}>
              {text.map((text, index) => (
                <TableCell
                  key={text}
                  sx={{
                    fontWeight: "bold",
                    width: "auto",
                    padding: "8px",
                    whiteSpace: "nowrap",
                    ...(location.pathname.includes("Finance") ||
                    location.pathname.includes("detailed")
                      ? {}
                      : { textAlign: align(index) }),
                  }}
                >
                  {text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {noResultsFound ? (
              <TableRow>
                <TableCell
                  colSpan={text.length} // Span across all columns
                  align="center"
                  sx={{
                    padding: "16px",
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)", // Optional: lighter text color
                  }}
                >
                  {foundResultsText}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {currentResults.map((result, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      [`@media (max-width: 600px`]: {
                        flexWrap: "wrap",
                      },
                    }}
                  >
                    {location.pathname.includes("results") && (
                      <TableCellData
                        Children={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            {/* Icons for Status */}
                            {result.Status === 1 ? (
                              <CheckCircleOutlineOutlinedIcon
                                sx={{ color: "green" }}
                                titleAccess="Готово"
                              />
                            ) : result.Status === 3 ? (
                              <UpdateIcon
                                sx={{ color: "orange" }}
                                titleAccess="Обработва се"
                              />
                            ) : result.Status === 2 ? (
                              <StartIcon
                                sx={{ color: "red" }}
                                titleAccess="Няма резултат"
                              />
                            ) : (
                              <ErrorIcon
                                sx={{ color: "red" }}
                                titleAccess="Грешка"
                              />
                            )}
                            {/* Icon for Pay */}
                            {(() => {
                              const hasPermission =
                                role === "patient"
                                  ? config.allowSeeResultPatient
                                  : role === "doctor"
                                  ? config.allowSeeResultDoctor
                                  : role === "affiliate"
                                  ? config.allowSeeResultAffiliate
                                  : false;

                              if (hasPermission) {
                                return result.Pay === 1 ? (
                                  <AttachMoneyIcon
                                    sx={{ color: "green" }}
                                    titleAccess="Платено"
                                  />
                                ) : (
                                  <AttachMoneyIcon
                                    sx={{ color: "red" }}
                                    titleAccess="Неплатено"
                                  />
                                );
                              }
                              return null;
                            })()}
                          </Box>
                        }
                      />
                    )}
                    {resultCell.map((e, index) => (
                      <TableCellData key={`${index}-${e}`} result={result[e]} />
                    ))}
                    {!(
                      location.pathname.includes("Finance") ||
                      location.pathname.includes("detailed")
                    ) && (
                      <TableCellData
                        Children={
                          <Button
                            variant="contained"
                            disabled={
                              (role === "patient" &&
                                config.allowSeeResultPatient) ||
                              (role === "doctor" &&
                                config.allowSeeResultDoctor) ||
                              (role === "affiliate" &&
                                config.allowSeeResultAffiliate)
                                ? result.Pay !== 1
                                : false
                            }
                            onClick={() =>
                              handleViewResult(
                                onClickData.map((e) => result[e])
                              )
                            }
                            sx={{
                              textTransform: "none",
                              fontSize: {
                                xs: "0.75rem",
                                sm: "0.875rem",
                                md: "1rem",
                              },
                              padding: {
                                xs: "4px 8px",
                                sm: "6px 12px",
                                md: "8px 16px",
                              },
                              backgroundColor: config.footerBarColor,
                              "&:hover": {
                                backgroundColor: `${config.buttonSearchColorHover} !important`,
                              },
                              display: "block",
                              marginLeft: !location.pathname.includes("results")
                                ? "auto"
                                : "unset",
                              marginRight: "10px",
                            }}
                            aria-label={"Преглед на резултат"}
                          >
                            {buttonText}
                          </Button>
                        }
                      />
                    )}
                    {location.pathname.includes("results") && (
                      <TableCellData
                        Children={
                          result.Document === 1 ? (
                            <AttachmentIcon
                              sx={{
                                color: config.paperClipColor,
                                "&:hover": {
                                  transform: "scale(1.2)",
                                  transition: "transform 0.3s ease-in-out",
                                  color: config.paperClipColorHover,
                                },
                              }}
                              onClick={() => {
                                showAllAttachments(result.IdEpruvetka);
                              }}
                            />
                          ) : null
                        }
                        sx={{ align: "right" }}
                      />
                    )}
                  </TableRow>
                ))}
                {!isSmallTable &&
                  !location.pathname.includes("examinations") &&
                  !location.pathname.includes("results") &&
                  !location.pathname.includes("documents") &&
                  !location.pathname.includes("attachments") && (
                    <TableRow>
                      {Array(resultCell.length - 1)
                        .fill("")
                        .map((_, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              fontWeight: "bold",
                              padding: "4px 8px",
                              fontSize: "0.775rem",
                            }}
                          />
                        ))}
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          textAlign: "right",
                          padding: "4px 8px",
                          fontSize: "0.775rem",
                          textWrap: "nowrap",
                        }}
                      >
                        {`Общо: ${sumLastColumn.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                  )}
              </>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredResults?.length || 0}
          // count={results?.length || 0}// Общ брой резултати
          page={currentPage}
          onPageChange={handlePageChange} // Смяна на страницата
          rowsPerPage={resultsPerPage}
          onRowsPerPageChange={handleResultsPerPageChange}
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          labelRowsPerPage="Покажи"
          labelDisplayedRows={() => null} // Скриване на стандартния текст "Страница X от Y"
          sx={{
            background: "#F6F6F8",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "40px", // Adjusts overall row height
            minHeight: "unset", // Removes default minimum height
            "& .MuiTablePagination-root": {
              "& .MuiPaper-root": {
                left: "auto !important",
              },
            },
            "& .MuiTablePagination-toolbar": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 8px", // Reduces padding in pagination toolbar
              minHeight: "unset", // Removes any minimum height restriction
            },
            "& .MuiTablePagination-caption, & .MuiTablePagination-selectLabel":
              {
                margin: "0 10px",
                fontSize: "0.75rem", // Reduces font size of labels
                padding: "0 4px", // Adjusts padding
              },
            "& .MuiTablePagination-actions": {
              display: "flex",
              justifyContent: "flex-end",
              order: 2,
            },
            "& .MuiToolbar-root": {
              justifyContent: "center",
            },
            "& .MuiTablePagination-spacer": {
              flex: "0 0 auto",
            },
            "& .MuiSelect-root": {
              marginRight: "5px", //  dropdown spacing
            },
          }}
          // Къстъмизация на бутоните за страници
          ActionsComponent={({ count, page, rowsPerPage, onPageChange }) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={(event) => onPageChange(event, page - 1)}
                disabled={page === 0}
                aria-label="Предишна страница"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography variant="body2" sx={{ margin: "0 10px" }}>
                {page + 1} / {Math.max(1, Math.ceil(count / rowsPerPage))}
              </Typography>
              <IconButton
                onClick={(event) => onPageChange(event, page + 1)} // Следваща страница
                disabled={
                  page >= Math.max(1, Math.ceil(count / rowsPerPage)) - 1
                }
                aria-label="Следваща страница"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          )}
        />
      </TableContainer>
    </Box>
  );
}
