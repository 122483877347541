import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Button } from "@mui/material";

const MOBILE_MAX_WIDTH = "959px";
const DESKTOP_MIN_WIDTH = "960px";

const CloseButton = ({ handleCloseModal, colors }) => (
  <IconButton
    onClick={handleCloseModal}
    sx={{
      position: "absolute",
      top: 8,
      right: 8,
      width: "32px",
      height: "32px",
      bgcolor: colors.background,
      color: colors.color,
      borderRadius: 0,
      "&:hover": {
        bgcolor: colors.hoverBackground,
      },
      boxShadow: "none",
      [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
        bgcolor: colors.background,
        color: colors.color,
      },
    }}
  >
    <CloseIcon />
  </IconButton>
);

const PdfViewerComponent = ({ base64Data, colors, defaultScale, fileName }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;
  const transformToolbar = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
  });
  const { config } = useSelector((state) => state.user);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = `${fileName}.pdf`;
    link.click();
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>{renderDefaultToolbar(transformToolbar)}</Toolbar>
    ),
  });
  const setRenderRange = React.useCallback((visiblePagesRange) => {
    return {
      startPage: visiblePagesRange.startPage,
      endPage: visiblePagesRange.endPage, //changed from "visiblePagesRange.endPage-1"
    };
  }, []);
  return (
    <>
      <Box
        id="pdf-viewer-container"
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          overflow: "visible",
          bgcolor: colors.background,
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
            height: "100%",
            borderRadius: 0,
            bgcolor: colors.background,
          },
          [`@media (min-width: ${DESKTOP_MIN_WIDTH})`]: {
            margin: "20px",
          },
        }}
      >
        <Button
          onClick={handleDownload}
          variant="contained"
          sx={{
            marginTop: 2,
            bgcolor: config.buttonEnterColor,
            display: "flex",
            alignSelf: "flex-end",
            "&:hover": {
              bgcolor: config.buttonSearchColorHover,
            },
          }}
        >
          Свали PDF
        </Button>
        <Viewer
          fileUrl={`data:application/pdf;base64,${base64Data}`}
          plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]}
          defaultScale={defaultScale}
          onRenderSuccess={setRenderRange}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    </>
  );
};

export default function PdfViewer({ base64Data, handleCloseModal, fileName }) {
  const { config } = useSelector((state) => state.user);
  const [defaultScale, setDefaultScale] = useState(1.0);
  useEffect(() => {
    const handleResize = () => {
      setDefaultScale(window.innerWidth < 960 ? 0.9 : 1.5);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    window.scrollTo(0, 0);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const beforePrint = () => {
      const canvasElements = document.querySelectorAll(
        "#pdf-viewer-container canvas"
      );
      canvasElements.forEach((canvas) => {
        canvas.style.width = "100vw";
        canvas.style.height = "auto";
      });
    };

    window.addEventListener("beforeprint", beforePrint);

    return () => window.removeEventListener("beforeprint", beforePrint);
  }, []);

  if (!config) {
    return null;
  }

  const viewerConfig =
    window.innerWidth < 960
      ? config.pdfViewer.mobile
      : config.pdfViewer.desktop;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          padding: 0,
          bgcolor: viewerConfig.layout.background,
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: viewerConfig.layout.pdfContainerBackground,
            borderRadius: "8px 8px 0 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "15px",
            paddingRight: "40px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            position: "relative",
            height: "calc(100% - 40px)",
            margin: "20px 20px 0 20px",
            [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
              bgcolor: "transparent",
              padding: 0,
              margin: 0,
              display: "block",
            },
          }}
        >
          <CloseButton
            handleCloseModal={handleCloseModal}
            colors={viewerConfig.closeButton}
          />

          <Box
            sx={{
              width: "100%",
              height: "100%",
              [`@media (max-width: ${MOBILE_MAX_WIDTH})`]: {
                width: "100%",
                height: "calc(100vh - 50px)",
                padding: 0,
                marginTop: "50px",
                bgcolor: viewerConfig.pdfViewer.background,
              },
              [`@media (min-width: ${DESKTOP_MIN_WIDTH})`]: {
                margin: "20px",
              },
            }}
          >
            {base64Data ? (
              <PdfViewerComponent
                base64Data={base64Data}
                colors={viewerConfig.pdfViewer}
                defaultScale={defaultScale}
                fileName={fileName}
              />
            ) : (
              <Box sx={{ color: "#ffffff", textAlign: "center" }}>
                Няма налични PDF данни
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Worker>
  );
}
