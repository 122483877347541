export const dataExaminations = ["Дата", "Вид преглед", "Преглед"];
export const dataDoctorExaminations = [
  "Дата",
  "Вид преглед",
  "ЕГН",
  "Име",
  "Презиме",
  "Фамилия",
  "Преглед",
];
export const dataAffiliateExaminations = [
  "Дата",
  "Вид преглед",
  "ЕГН",
  "Име",
  "Презиме",
  "Фамилия",
  "Преглед",
];
export const dataResults = ["Статус", "Дата", "Пакет", "Резултат", "Документи"];
export const dataDoctorResults = [
  "Статус",
  "Дата",
  "Пакет",
  "ЕГН",
  "Име",
  "Презиме",
  "Фамилия",
  "Резултат",
  "Документи",
];
export const dataDoctorResultsGrouped = [
  "Статус",
  "Дата",
  "Пакет",
  "ЕГН",
  "Име",
  "Резултат",
  "Документи",
];

export const dataAffiliateResults = [
  "Статус",
  "Дата",
  "Пакет",
  "ЕГН",
  "Име",
  "Презиме",
  "Фамилия",
  "Резултат",
  "Документи",
];
export const dataDocuments = ["Дата", "Вид документ", "Документи"];
export const dataAttachments = ["Дата", "Вид документ", "Документи"];

export const dataLogin = [
  {
    buttonId: "patient-login-btn",
    buttonOnClick: "patient-form",
    buttonText: "Вход за пациенти",
    buttonIcon: true,
    id: "patient-form",
    visible: "patient-form",
    onSubmit: "patient",
    errors: ["patient-id-error", "patient-password-error"],
    inputs: ["patient-id", "patient-password"],
    placeholder: ["ID номер", "Парола"],
  },
  {
    buttonId: "doctor-login-btn",
    buttonOnClick: "doctor-form",
    buttonText: "Вход за лекари",
    buttonIcon: true,
    id: "doctor-form",
    visible: "doctor-form",
    onSubmit: "doctor",
    errors: ["doctor-id-error", "doctor-password-error"],
    inputs: ["doctor-id", "doctor-password"],
    placeholder: ["УИН", "Парола"],
  },
  {
    buttonId: "affiliate-login-btn",
    buttonOnClick: "affiliate-form",
    buttonText: "Вход за филиали",
    buttonIcon: true,
    id: "affiliate-form",
    visible: "affiliate-form",
    onSubmit: "affiliate",
    errors: ["affiliate-id-error", "affiliate-password-error"],
    inputs: ["affiliate-id", "affiliate-password"],
    placeholder: ["ID филиал", "Парола"],
  },
];
export const dataFinance = ["Схема на продажба", "Сума"];
export const dataDetailedFinance = [
  "ID",
  "Парола",
  "Дата изпълнение",
  "ЕГН",
  "Име",
  "Презиме",
  "Фамилия",
  "Схема продажба",
  "Сума напр.",
];
export const dataDetailedFinanceGrouped = [
  "ID",
  "Парола",
  "Дата",
  "ЕГН",
  "Име",
  "",
  "",
  "Схема",
  "Сума",
];
