import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function useInitialExamsData() {
  const [fetchedData, setFetchedData] = useState([]);
  const [error, setError] = useState(null);
  const { config, role, id, IDPacient } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");

  const fetchExaminationData = async ({
    dateTo,
    dateFrom,
    filterEGN,
    filterName,
  }) => {
    setError(null);

    try {
      const requestBody = {
        [role === "patient"
          ? "IDPacient"
          : role === "doctor"
          ? "IDDoctorGlab"
          : "IDAffiliate"]: role === "affiliate" ? IDPacient : id,
        DateFrom:
          dateFrom instanceof Date ? dateFrom.toISOString().split("T")[0] : "",
        DateTo:
          dateTo instanceof Date ? dateTo.toISOString().split("T")[0] : "",
        Identifier: filterEGN || "",
        Family: filterName || "",
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/MedicsResultsService/GetExamination${
          role.charAt(0).toUpperCase() + role.slice(1)
        }`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.length > 0) {
        setFetchedData(response.data);
      } else {
        setFetchedData([]);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching examination results:", error);
      setError(error.message || "An unknown error occurred.");
    }
  };

  return { fetchExaminationData, id, fetchedData, error };
}
