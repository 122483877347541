import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeId, changeIDPacient } from "../slices/userReducer";

export const useGetIdData = () => {
  const token = localStorage.getItem("token");
  const { role } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      try {
        const [, payload] = token.split(".");
        if (payload) {
          const decodedPayload = JSON.parse(atob(payload));

          let id, IDPacient;
          if (role === "patient") {
            id = decodedPayload.usrmedics;
            IDPacient = decodedPayload.usrlab;
          } else if (role === "doctor") {
            id = decodedPayload.usrlekar;
          } else if (role === "affiliate") {
            IDPacient = decodedPayload.usraffiliate;
          }

          if (id) {
            dispatch(changeId(id));
            localStorage.setItem("id", "id");
          }
          if (IDPacient) {
            dispatch(changeIDPacient(IDPacient));
            localStorage.setItem("IDPacient", "IDPacient");
          }
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token, role, dispatch]);
};
