import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeLoading } from "../slices";
import { useEffect, useState } from "react";

export const useHandleViewResult = () => {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(false);
  const [base64Data, setBase64Data] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleViewResultPdf = async (
    IdEpruvetka,
    TipRezultat,
    fetchedData,
    token
  ) => {
    setErrorMessage("");
    dispatch(changeLoading(true));
    const requestBody = {
      IDEpruvetka: IdEpruvetka,
      TipResultat: TipRezultat,
    };

    try {
      const responsePdf = await axios.post(
        `${config.apiBaseUrl}/GLabService/GetReferralPatientPDF`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (responsePdf.status === 200 && responsePdf.data.length > 0) {
        const base64Pdf = responsePdf.data[0].DocumentPdf;
        setBase64Data(base64Pdf);
        setOpenModal(true);
      } else {
        // Stop spinner
        throw new Error("No access to this document");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error.message);
      // Show message to the user
      setErrorMessage("В момента няма достъп до този документ.");
    } finally {
      dispatch(changeLoading(false));
    }
  };
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(""); // Clear error message after delay
      }, 5000);

      return () => clearTimeout(timer); // Cleanup timer on unmount or when errorMessage changes
    }
  }, [errorMessage]);
  return [
    handleViewResultPdf,
    openModal,
    handleCloseModal,
    base64Data,
    errorMessage,
  ];
};
