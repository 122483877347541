import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FadeMenu from "./DropDownMenu";
import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { logout } from "../../slices";

function AppHeader() {
  const { role, config } = useSelector((state) => state.user);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let userName = "";
  let UIN = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    if (role === "patient") {
      userName = decodedToken.pac || "No patient name!";
    } else if (role === "doctor") {
      userName = decodedToken.lekar || "No doctor name!";
      UIN = decodedToken.uin || "No UIN!";
    } else if (role === "affiliate") {
      userName = decodedToken.affiliate || "No affiliate name!";
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triggerRef = React.useRef(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAnchorEl(null);
    dispatch(logout());
    navigate("/");
  };
  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 0,
          // border: `2px solid ${config.appBarColor}`,
          borderBottom: `2px solid ${config.footerBarColor}`,
          // background: config.appBarColor,
          bgcolor: config.appBarBGColor,
        }}
      >
        <AppBar
          position="static"
          className="header"
          sx={{
            boxShadow: "none",
            flexShrink: 0,
            color: "#6e1646",
            textAlign: "center",
            padding: "0 20px",
            bgcolor: config.appBarBGColor,
            "& .MuiTypography-root": {
              marginTop: "5px",
            },
            "&header.MuiPaper-root": {
              padding: "0 !important",
            },
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5px",
              bgcolor: config.appBarBGColor,
            }}
          >
            {role === "patient" && (
              <Fragment>
                <AccountCircleOutlinedIcon
                  ref={triggerRef}
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="iconPacient"
                  sx={{
                    fontSize: "2rem",
                    color: config.appBarColor,
                    bgcolor: config.appBarBGColor,
                    cursor: "pointer",
                  }}
                ></AccountCircleOutlinedIcon>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& div.MuiPaper-root": {
                      color: config.appBarColor,
                      bgcolor: config.appBarBGColor,
                      padding: 0,
                    },
                    "&hover": {
                      backgroundColor: config.buttonSearchColorHover,
                    },
                    "& .MuiMenuItem-root": {
                      padding: "5px", // Removes padding from MenuItem elements
                    },
                  }}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                  <MenuItem onClick={handleLogout}>Изход</MenuItem>
                </Menu>
              </Fragment>
            )}

            {role === "doctor" && (
              <Fragment>
                <AccountCircleOutlinedIcon
                  ref={triggerRef}
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="iconDoctor"
                  sx={{
                    fontSize: "2rem",
                    color: config.appBarColor,
                    bgcolor: config.appBarBGColor,
                    cursor: "pointer",
                  }}
                ></AccountCircleOutlinedIcon>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& div.MuiPaper-root": {
                      color: config.appBarColor,
                      bgcolor: config.appBarBGColor,
                    },
                    "&hover": {
                      backgroundColor: config.buttonSearchColorHover,
                    },
                  }}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                  <MenuItem onClick={handleLogout}>Изход</MenuItem>
                </Menu>
              </Fragment>
            )}

            {role === "affiliate" && (
              <Fragment>
                <AccountCircleOutlinedIcon
                  ref={triggerRef}
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="iconAffiliate"
                  sx={{
                    fontSize: "2rem",
                    color: config.appBarColor,
                    bgcolor: config.appBarBGColor,
                    cursor: "pointer",
                  }}
                ></AccountCircleOutlinedIcon>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& div.MuiPaper-root": {
                      color: config.appBarColor,
                      bgcolor: config.appBarBGColor,
                    },
                    "&hover": {
                      backgroundColor: config.buttonSearchColorHover,
                    },
                  }}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                  <MenuItem onClick={handleLogout}>Изход</MenuItem>
                </Menu>
              </Fragment>
            )}
            <a
              href={config.labHomePageUrl}
              id="logo-button"
              aria-label="Go to Home Page"
            >
              <img id="logo" src={config.logoPath} alt="Logo" />
            </a>
            {/* <img id="logo" src={config.logoPath} alt="Logo" /> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <b style={{ textDecoration: "none" }}>
                <span
                  style={{
                    lineHeight: "0.1",
                    wordBreak: "break-word",
                    color: config.appBarColor,
                    background: config.appBarBGColor,
                  }}
                >
                  <v style={{ fontSize: "25px" }}>СМДЛ МЕДИЛАБ</v> <br />
                  Клинична и Микробиологична <br />
                  лаборатория
                </span>
              </b>
            </div> */}

            {(role === "patient" ||
              role === "doctor" ||
              role === "affiliate") && (
              <IconButton
                className="iconMenu"
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  mr: 2,
                  backgroundColor: "transparent",
                  right: "4vw",
                  position: "fixed",
                }}
              >
                <FadeMenu sx={{ top: "30px" }} />
              </IconButton>
            )}
          </Typography>
        </AppBar>
      </Box>
      {location.pathname !== "/" && (
        <div
          className="user-info"
          style={{
            display: "flex",
            justifyContent: "center",
            color: config.footerBarColor,
          }}
        >
          {role === "doctor" ? `${userName} УИН: ${UIN}` : userName}
        </div>
      )}
    </Fragment>
  );
}
export default AppHeader;
