import { useSelector } from "react-redux";
function Footer() {
  const { config } = useSelector((state) => state.user);
  return (
    <footer
      className="footer"
      style={{
        backgroundColor: config.footerBarColor,
        color: "white",
        padding: 0,
      }}
    >
      <div
        className="footer-content"
        style={{ backgroundColor: config.footerBarColor }}
      >
        <a
          href={"http://gss.bg/"}
          target="www.gss.bg"
          rel="noopener noreferrer"
          style={{
            color: "white",
            padding: "5px",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          ©<span>{new Date().getFullYear()}</span> Global System Solutions Ltd.
          Всички права запазени.
        </a>
      </div>
    </footer>
  );
}
export default Footer;
