import { Fragment, useEffect, useState } from "react";
import Flatpickr from "../Components/Flatpickr";
import Table from "../Components/Table/Table";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dataAffiliateExaminations } from "../utils";
import TextContentInfo from "../Components/TextContentInfo";
import { changeLoading } from "../slices/userReducer";
import useInitialExamsData from "../hooks/useInitialExamsData";
import { Box, Modal, useMediaQuery } from "@mui/material";
import AppHeader from "../Components/navigation/AppHeader";
import { PdfViewer } from "../Components";
import VerticalTable from "../Components/Table/VerticalTable";

export default function AffiliateExaminations() {
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(100);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const { config, IDPacient } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [examinationData, setExaminationData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [base64Data, setBase64Data] = useState(null);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [errorMessage, setErrorMessage] = useState("");
  const [filterEGN, setFilterEGN] = useState("");
  const [filterName, setFilterName] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const { fetchExaminationData, fetchedData } = useInitialExamsData();
  useEffect(() => {
    if (location.state?.data && location.state.data.length > 0) {
      setExaminationData(location.state.data);
    }
  }, [location.state?.data, navigate]);
  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      setExaminationData(fetchedData);
    }
  }, [fetchedData]);

  const handleShowAllResults = async () => {
    if (!dateFrom || !dateTo) return;
    dispatch(changeLoading(true));
    if (IDPacient) {
      const newData = await fetchExaminationData({
        dateTo,
        dateFrom,
        filterEGN,
        filterName,
      });

      setExaminationData(newData || []);
    }
    dispatch(changeLoading(false));
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };

  const handleViewResult = async ([Id, IdPac, IdDoc]) => {
    dispatch(changeLoading(true));
    try {
      const requestBody = {
        IDDoctor: IdDoc,
        IDAmbulat: Id,
        IDPacient: IdPac,
      };

      const responsePdf = await axios.post(
        `${config.apiBaseUrl}/MedicsResultsService/GetExaminationPatientPDF`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (responsePdf.status === 200 && responsePdf.data.length > 0) {
        const base64Pdf = responsePdf.data[0].DocumentPdf;
        setBase64Data(base64Pdf);
        setOpenModal(true);
      } else {
        // Stop spinner
        throw new Error("No access to this document");
      }
    } catch (error) {
      console.error("Error fetching PDF:", error.message);
      // Show message to the user
      setErrorMessage("В момента няма достъп до този документ.");
    } finally {
      dispatch(changeLoading(false));
    }
  };
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(""); // Clear error message after delay
      }, 5000);

      return () => clearTimeout(timer); // Cleanup timer on unmount or when errorMessage changes
    }
  }, [errorMessage]);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const noResultsFound = examinationData.length === 0;
  return (
    <Fragment>
      <TextContentInfo text="Прегледи" />
      {errorMessage && <div id="badRequest">{errorMessage}</div>}
      <Flatpickr
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        handleShowAllResults={handleShowAllResults}
        filterEGN={filterEGN}
        setFilterEGN={setFilterEGN}
        filterName={filterName}
        setFilterName={setFilterName}
        setErrorMessage={setErrorMessage}
      />

      {isMobile ? (
        <VerticalTable
          results={examinationData}
          resultCell={[
            "DatePreg",
            "Performer",
            "IdentifierPac",
            "GivenPacName",
            "MiddlePacName",
            "FamilyPacName",
          ]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["Id", "IdPac", "IdDoc"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataAffiliateExaminations}
          buttonText="Виж преглед"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          filterEGN={filterEGN}
          filterName={filterName}
          noResultsFound={noResultsFound}
        />
      ) : (
        <Table
          results={examinationData}
          resultCell={[
            "DatePreg",
            "Performer",
            "IdentifierPac",
            "GivenPacName",
            "MiddlePacName",
            "FamilyPacName",
          ]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["Id", "IdPac", "IdDoc"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataAffiliateExaminations}
          buttonText="Виж преглед"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          filterEGN={filterEGN}
          filterName={filterName}
          noResultsFound={noResultsFound}
        />
      )}
      <Modal
        className="pdf-viewer-container"
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 25000,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 1,
            p: 2,
            boxShadow: 24,
          }}
        >
          <AppHeader />
          {base64Data && (
            <PdfViewer
              base64Data={base64Data}
              fileName={`${examinationData[0].DatePreg}_${examinationData[0].Performer}_${examinationData[0].GivenPacName}_${examinationData[0].MiddlePacName}_${examinationData[0].FamilyPacName}.pdf`}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </Fragment>
  );
}
