import { Fragment, useEffect, useState } from "react";
import Table from "../Components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dataDoctorResults, dataDoctorResultsGrouped } from "../utils/helpers";
import TextContentInfo from "../Components/TextContentInfo";
import useInitialResultsData from "../hooks/useInitialResultsData";
import { changeLoading } from "../slices/userReducer";
import Flatpickr from "../Components/Flatpickr";
import { useHandleViewResult } from "../hooks";
import Modal from "@mui/material/Modal";
import { Box, useMediaQuery } from "@mui/material";
import AppHeader from "../Components/navigation/AppHeader";
import { GroupedResultsTableMobile, PdfViewer } from "../Components";

export default function DoctorResults() {
  const [currentPage, setCurrentPage] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(100);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const { config, id } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [examinationData, setExaminationData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 960px)");
  const [errorMessage, setErrorMessage] = useState("");
  const [filterEGN, setFilterEGN] = useState("");
  const [filterName, setFilterName] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const { fetchResultsData, fetchedData } = useInitialResultsData();

  useEffect(() => {
    if (location.state?.data && location.state.data.length > 0) {
      setExaminationData(location.state.data);
    }
  }, [location.state?.data, navigate]);

  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      setExaminationData(fetchedData);
    }
  }, [fetchedData]);

  const handleShowAllResults = async () => {
    if (!dateFrom || !dateTo) return;
    dispatch(changeLoading(true));
    if (id) {
      const newData = await fetchResultsData({
        dateTo,
        dateFrom,
        config,
        filterEGN,
        filterName,
      });
      setExaminationData(newData || []);
    }
    dispatch(changeLoading(false));
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleResultsPerPageChange = (event) => {
    setResultsPerPage(parseInt(event.target.value));
    setCurrentPage(0);
  };
  const [handleViewResultPdf, openModal, handleCloseModal, base64Data, error] =
    useHandleViewResult();

  const handleViewResult = async ([IdEpruvetka, TipRezultat]) => {
    handleViewResultPdf(IdEpruvetka, TipRezultat, fetchedData, token);
  };
  const noResultsFound = examinationData.length === 0;
  return (
    <Fragment>
      <TextContentInfo text="Резултати" />
      {errorMessage && <div id="badRequest">{error}</div>}
      <Flatpickr
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        handleShowAllResults={handleShowAllResults}
        filterEGN={filterEGN}
        setFilterEGN={setFilterEGN}
        filterName={filterName}
        setFilterName={setFilterName}
        setErrorMessage={setErrorMessage}
      />

      {isMobile ? (
        <GroupedResultsTableMobile
          results={examinationData}
          resultCell={[
            "DateRefferal",
            "Paket",
            "IdentifierPac",
            "GivenPacName",
            "MiddlePacName",
            "FamilyPacName",
          ]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["IdEpruvetka", "TipRezultat"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataDoctorResultsGrouped}
          buttonText="Виж резултат"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          filterEGN={filterEGN}
          filterName={filterName}
          noResultsFound={noResultsFound}
        />
      ) : (
        <Table
          results={examinationData}
          resultCell={[
            "DateRefferal",
            "Paket",
            "IdentifierPac",
            "GivenPacName",
            "MiddlePacName",
            "FamilyPacName",
          ]}
          currentPage={currentPage}
          resultsPerPage={resultsPerPage}
          handleViewResult={handleViewResult}
          onClickData={["IdEpruvetka", "TipRezultat"]}
          handlePageChange={handlePageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          text={dataDoctorResults}
          buttonText="Виж резултат"
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          filterEGN={filterEGN}
          filterName={filterName}
          noResultsFound={noResultsFound}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 25000,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 2,
            boxShadow: 24,
          }}
        >
          <AppHeader />
          {base64Data && (
            <PdfViewer
              base64Data={base64Data}
              fileName={`${examinationData[0].DateRefferal}_${examinationData[0].Paket}_${examinationData[0].GivenPacName}_${examinationData[0].MiddlePacName}_${examinationData[0].FamilyPacName}`}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </Fragment>
  );
}
