import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export default function Spinner() {
  const { config } = useSelector((state) => state.user);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // backgroundColor: "rgba(255, 255, 255, 1)",
        backgroundColor: config.footerBarColor,
        zIndex: 27000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          // justifyContent: "space-between",
          paddingBottom: "50px",
          paddingTop: "50%",
          "@keyframes pulse": {
            "0%": { transform: "scale(0.9)" },
            "50%": { transform: "scale(1.2)" },
            "100%": { transform: "scale(0.9)" },
          },
        }}
      >
        <img
          src={`/${config.logoPathGSS}`}
          alt="Logo"
          style={{
            width: "240px",
            height: "80px",
            position: "absolute",
            zIndex: 27001,
            animation: "pulse 1s infinite ease-in-out",
          }}
        />
        <p
          style={{
            color: "white",
            fontSize: "16px",
            zIndex: 27001,
            paddingTop: "120px",
            animation: "pulse 1s infinite ease-in-out",
          }}
        >
          Зареждане...
        </p>
        {/* <CircularProgress /> */}
      </Box>
    </Box>
  );
}
