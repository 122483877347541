import { Box, Typography } from "@mui/material";

export default function TextContentInfo({ text }) {
  return (
    <Box
      text={text}
      sx={{
        marginLeft: "10%",
        "@media (max-width: 960px)": {
          fontSize: "12px",
        },
      }}
    >
      <Typography
        variant="h5"
        sx={{
          marginBottom: "10px",
          "@media (max-width: 960px)": {
            marginTop: "3px",
            marginBottom: "5px",
            fontSize: "1rem",
          },
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
