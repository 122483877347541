import { TableCell } from "@mui/material";
import React from "react";

export default function TableCellData({ result, Children, sx }) {
  return (
    <TableCell
      sx={{
        wordBreak: "break-word", // Прекъсва дълги думи
        whiteSpace: "normal", // Позволява пренасяне на текст
        textOverflow: "ellipsis", // Показва многоточие за дълги текстове
        maxWidth: "300px",
        padding: "2px",
        [`@media (max-width: 600px)`]: {
          maxWidth: "100%",
        },
      }}
      {...sx}
    >
      {result && result}
      {Children && Children}
    </TableCell>
  );
}
