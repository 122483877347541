import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoading } from "../../slices";

function LoginFormPacient({
  id,
  visible,
  onSubmit,
  errors = [],
  inputs = [],
  placeholder,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const { config } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleOnSubmit = async () => {
    dispatch(changeLoading(true));
    try {
      await onSubmit();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(changeLoading(false));
    }
  };
  return (
    <Container
      id={id}
      className="form-container"
      display={visible ? "flex" : "none"}
      $themecolor={config.loginTextColor}
    >
      {inputs.map((inputId, index) => (
        <div key={inputId} className="input-container">
          <div className="input-wrapper" style={{ backgroundColor: "#F6F6F8" }}>
            <input
              style={{
                border: `1px solid ${config.loginTextColor}`,
              }}
              type={
                inputId.includes("password")
                  ? showPassword
                    ? "text"
                    : "password"
                  : "text"
              }
              id={inputId}
              placeholder={placeholder[index]}
              onChange={(e) => {
                if (inputId.includes("password")) {
                  setPasswordInput(e.target.value);
                }
              }}
            />

            {inputId.includes("password") && passwordInput.length > 0 && (
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="password-toggle"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            )}
          </div>
          {errors.includes(`${inputId}-error`) && (
            <div id={`${inputId}-error`} className="error-message">
              {inputId.includes("password")
                ? "Моля въведи парола!"
                : "Моля въведи ID!"}
            </div>
          )}
        </div>
      ))}
      <StyledButton
        className="enterBtn"
        onClick={handleOnSubmit}
        $themecolor={config.loginTextColor}
        $enterButtonHover={config.contentButtonColorHover}
      >
        Вход
      </StyledButton>
    </Container>
  );
}

export default LoginFormPacient;

const Container = styled.div`
  display: ${({ display }) => display};
  margin-top: -1px;
  border: 1px solid ${({ $themecolor }) => $themecolor};
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .input-container {
    position: relative;
    margin-bottom: 10px;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    padding-right: 40px; /* Make space for the icon */
    outline: none;
  }

  .password-toggle {
    position: absolute;
    right: 0;
    background: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: right;
    justify-content: center;
    color: ${({ $themecolor }) => $themecolor};
    width: 0;
    margin-right: 10px;
  }
  .password-toggle:hover {
    background: none;
  }

  .form-container {
    border: 1px solid ${({ $themecolor }) => $themecolor};
  }
`;
const StyledButton = styled.button`
  background-color: ${({ $themecolor }) => $themecolor};
  border: 1px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ $enterButtonHover }) => $enterButtonHover};
  }
`;
